var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CustomBottomSheet',{attrs:{"refName":'QuotationPriceHistoryInfo',"size":"xl","headerText":_vm.$t('QuotationPriceHistories.data'),"headerIcon":_vm.quotationPriceHistory.icon}},[_c('div',{staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-md-12',"value":_vm.quotationPriceHistory.fullCode,"title":_vm.$t('QuotationPriceHistories.code'),"imgName":'code.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.quotationPriceHistory.placeInfoData
          ? _vm.quotationPriceHistory.placeInfoData.fullCode
          : '',"title":_vm.$t('Places.code'),"imgName":'code.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.quotationPriceHistory.placeInfoData
          ? _vm.quotationPriceHistory.placeInfoData.placeNameCurrent
          : '',"title":_vm.$t('suppliers.name'),"imgName":'places.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.quotationPriceHistory.priceQuotationDetailsInfoData &&
        _vm.quotationPriceHistory.priceQuotationDetailsInfoData.storeItemInfoData
          ? _vm.quotationPriceHistory.priceQuotationDetailsInfoData
              .storeItemInfoData.fullCode
          : '',"title":_vm.$t('StoreItems.code'),"imgName":'code.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.quotationPriceHistory.priceQuotationDetailsInfoData &&
        _vm.quotationPriceHistory.priceQuotationDetailsInfoData.storeItemInfoData
          ? _vm.quotationPriceHistory.priceQuotationDetailsInfoData
              .storeItemInfoData.storeItemNameCurrent
          : '',"title":_vm.$t('StoreItems.name'),"imgName":'StoreItems.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.quotationPriceHistory.priceQuotationDetailsInfoData
          ? _vm.quotationPriceHistory.priceQuotationDetailsInfoData
              .quotationDetailsHistoryWorkTypeNameCurrent
          : '',"title":_vm.$t('status'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.quotationPriceHistory.totalItemsQuantity,"title":_vm.$t('general.quantity'),"imgName":'number.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.quotationPriceHistory.totalItemsAmountWithCurrency,"title":_vm.$t('StoreItems.price.totalPrice'),"imgName":'money.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.quotationPriceHistory.storeItemAmountWithCurrency,"title":_vm.$t('StoreItems.price.onePrice'),"imgName":'money.svg'}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }